import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";



export default function HealthyPlaces() {
  return (
    <Layout pageTitle="Healthy Places">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Healthy Places - USI Projects</title>
        <meta name="description" content="Healthy Places seeks to collect and share “Bright Ideas” for North Belfast through creative conversations and community engagement."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

       <section class="text-gray-700 body-font heading-container-brightideas">
       <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">Bright Ideas</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Public Health Agency</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-primaryteal">Overview</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl   ">In
                  conjunction with the Public Health Agency Northern Ireland, Healthy Places seeks to collect and share
                  “Bright Ideas” for North Belfast through creative conversations and community engagement.<br/><br/>

                  Focused in the neighbourhoods of Ardoyne and Ballysillan, ‘Healthy Places’ seeks to bring the
                  community to the core of development and regeneration. Together, the dual projects of ‘Street
                  Activation’ and ‘Participatory Communities’ have come under the umbrella of “Bright Ideas Belfast”,
                  with USI currently carrying out the initial public consultation, asking communities and individuals
                  for their Bright Ideas for North Belfast through workshops and engaging street activities.</p>

              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase  ">Fields of
                  Practice</p>
                <p 
                  class="my-4 text-gray-500 leading-relaxed  mt-0">Mental Health<br/>
                  Community Participation<br/>Regeneration<br/>Green Spaces</p>
                <p class="text-2xl font-bold uppercase    mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed    mt-0">Citizen
                  Engagement<br/>
                  Design Thinking<br/>Concept to Delivery<br/>Pop-up installations<br/>Toolkit Development</p>
                <p class="text-2xl font-bold uppercase     mt-8">Visit</p>
                <a href="https://www.brightideasbelfast.com" target="_blank">
                  <p class="my-4 text-gray-500 leading-relaxed mt-0">
                    brightideasbelfast.com
                  </p>
                </a>
              </div>
            </div>
          </section>
        </div>
      </section>


      <div class="md:p-16 md:m-16">
        <video controls src="https://player.vimeo.com/external/461798403.hd.mp4?s=67e9768ba0d525544e23fd14ec57715f4bbb81d8&profile_id=175">
        </video>
      </div>


      <img class="w-full mt-32" src="../img/projects/brightideas-timeline.webp" alt="Bright Ideas"/>

      <img class="w-full" src="https://ik.imagekit.io/usi/project-pages/IMG_0749_1_VNOIocBjQ.png" alt=""/>



      <div class="md:pl-20 md:pr-20 py-6">
        <div class="grid lg:grid-cols-2">
          <div
            class="align-items-center">
            <div class="w-full">
              <div class="p-5 relative">
                <img src="https://ik.imagekit.io/usi/project-pages/11_Healthy_Places_teepee__2__1_eSYncfS4al.png"
                  alt="tsukaeru" class="w-full mx-auto" />
                <p className="absolute bottom-10 left-10 text-white italic">Our ‘green teepee’ in North Belfast</p>
              </div>
            </div>
          </div>
          <div class="">
            <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
              class="text-xl md:text-2xl  p-6 md:mb-16  ">
              Within the ‘Street Activation’ the aim is to think creatively about how we might promote civic pride
              through place-based interventions. The focus of attention is in the relationship between green spaces,
              community cohesion and mental health. This involved a public consultation space in the form of a ‘green
              teepee’ in North Belfast, seeking to engage with those in the community that may not be directly linked to
              local community centres or organisations. Asking questions to explore how green spaces can be developed
              and utilised for the community, we developed a number of initiatives where communities can start to take
              ownership and pride in their area.
            </p>
          </div>
             <div class="p-5 relative">
                <img src="https://ik.imagekit.io/usi/project-pages/General_pic_v2_1_3ftTaPJxFoYFi.png" alt="tsukaeru"
                  class="w-full h-full" />
                <p className="absolute bottom-10 left-10 text-white italic">Collecting 'Bright Ideas' from the local community</p>
              </div>
              <div class="relative p-5">
                <img
                  src="https://ik.imagekit.io/usi/project-pages/WhatsApp_Image_2020-03-02_at_23.09_1_4_Cd7c9k8Ygal.png"
                  alt="tsukaeru" class="w-full mx-auto" />
                  <p className="absolute bottom-10 left-10 text-white italic">A walkaround the 'teepee' with members of the local community</p>
              </div>
        </div>
      </div>



      <img data-scroll data-scroll-offset="200" data-scroll-speed="2" class="w-full md:pl-20 md:pr-20"
        src="https://ik.imagekit.io/usi/project-pages/brightideas-logo_kR7pNdVko8kk.png" alt=""/>




      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div class="flex flex-wrap ">
            <div class="w-full ">
              <p class="text-xl md:text-2xl  py-12 md:w-4/6">
                Within the ‘Participatory Communities’ project, the main goal is to support local communities in
                identifying key issues in their neighbourhood and develop innovative and creative solutions in which the
                communities themselves can deliver on. The targeted issues are the anti-social behaviour, access to
                public space and community integration. A series of workshops with key groups and members of the
                community, held from January to March 2020 helped develop innovative and entrepreneurial ideas that aim
                to make a real and lasting change in their area.<br/><br/>

                Applying the World Health Organisation’s 5 Ways to Wellbeing, Healthy Places seeks to enrich lives and
                communities through civic ownership.
              </p>
              <div className="grid lg:grid-cols-2">
              <img data-scroll data-scroll-class="scroll-class" data-scroll-repeat="true"
                src="https://ik.imagekit.io/usi/project-pages/IMG_0757_2_VZ_DD7nPW.png"/>
              <img src="https://ik.imagekit.io/usi/project-pages/IMG_0762_1_-QT3s63wPBHq.png" alt="foyle app"/>

              </div>
              
            </div>
            
          </div>
        </div>
      </section>


      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 md:flex-row flex-col items-center">
          <div class="grid lg:grid-cols-2 gap-6 py-12">
            <div class="w-full">
              <p class="text-xl md:text-2xl ">
                The ideas generated by the community were voted on through an online platform and the most popular ideas
                were delivered throughout Covid. These included a uild-it-yourself birdbox distributed to 200 households
                to attract nature to the nightbourhood, a shared meal scheme between young and old residents, and a
                colourful co-created illustration distributed around the neighbourhood.
              </p>

            </div>
            <div data-scroll data-scroll-speed="4" class="w-full ">
              <img src="https://ik.imagekit.io/usi/project-pages/20200623_123921_copia_1_MLlo6rFMi.png" alt="foyle app"/>

            </div>
          </div>
        </div>
      </section>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-green-400">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../outdoorspaces" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
